// this is a singular 'talks' page

import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import {MainList} from '../components/utils.js'

const SlideDeck = ({post}) => {
	if (post.frontmatter.slidedeck) {
		return <a href={post.frontmatter.slidedeck} className={"tagLink"}>View the slidedeck</a>
	}
	else {return null;}

}

const Talk = ({ data }) => {
  const post = data.talk
  const totalCount = data.projects.totalCount
  const totalTalk = data.talks.totalCount
  console.log(post.frontmatter.slidedeck)
  return (
	<Layout>
		<div className={"container"}>
			<MainList total={totalCount} totalTalk={totalTalk}/>
			<h1>{post.frontmatter.title}</h1>
			{post.frontmatter.location.map((value, index)  => (
				<p key={'loc'+index}>
					<strong>{post.frontmatter.locDate[index]}</strong>: {value}
				</p>
			))}
			<SlideDeck post={post}/>
			<div dangerouslySetInnerHTML={{ __html: post.html }} />
			<MainList total={totalCount} totalTalk={totalTalk}/>
		</div>
	</Layout>
	)
}

export const query = graphql`
	query($slug: String!) {
		projects: allMarkdownRemark(
				limit: 2000
				filter: {frontmatter: {template: {eq: "project"}}}
			) {
				totalCount
			}
		talks: allMarkdownRemark(
				limit: 2000
				filter: {frontmatter: {template: {eq: "talk"}}}
			) {
				totalCount
			}
		talk: markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				category
				collaboration
				technology
				date(formatString: "MMMM DD, YYYY")
				location
				locDate(formatString: "MMMM DD, YYYY")
				slidedeck
			}
		}
	}
	`

export default Talk;